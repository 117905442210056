.switch-container {
    width: 100%;
}

.switch-container .row .col {
    margin-bottom: 0.1rem;
    /* border: dashed 1px red; */
    text-align: middle;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.charging-switch {
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #009900;
    background-color: #e6ffe6;
}

.device-caption {
    font-size: large;
    color: darkgreen;
}

.device-id {
    font-size: xx-large;
    color: grey;
}

.device-id-input {
    font-size: large;
    color: darkgreen;
}

a:link, a:visited, a:hover, a:active {
    font-size: small;
    text-decoration: none;
    color:grey;
}